import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { Link, graphql, useStaticQuery } from 'gatsby'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <span>prev</span>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <span>next</span>
    </div>
  )
}


export default function Service(props) {
  const data = useStaticQuery(graphql`
  query  {
    allHomeJson {
      nodes {
        Title
        id
        Intro
        Image
        
        RelatedServices {
          Description
          Title
          CtAText
          CTALink
        }
      }
    }
  }

  `)

  const [state, setstate] = useState([]);
  useEffect(() => {
    setstate([])
    data.allHomeJson.nodes.forEach(x => {
      x?.RelatedServices?.forEach(y => {
        setstate(z => [...z, y])
      });
    });


  }, [data.allHomeJson.nodes])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="bg-white py-5 px-3 Service">
      <Slider {...settings}>
        {/*   <div className="col-md-12 text-left px-5">
          <h6 className="font-weight-bolder">Heeey</h6>
          <p className="text-muted mb-3 pr-5">
            <small>lorem lorem lorem lorem</small>
          </p>
          <div to="/" className="parallelogram mb-4">
            <Link to="/" className="text-dark">
              More33
              </Link>
          </div>
        </div> <div className="col-md-12 text-left px-5">
          <h6 className="font-weight-bolder">Heeey</h6>
          <p className="text-muted mb-3 pr-5">
            <small>lorem lorem lorem lorem</small>
          </p>
          <div to="/" className="parallelogram mb-4">
            <Link to="/" className="text-dark">
              More33
              </Link>
          </div>
        </div> */}

        {state.map((node, index) => (

          <>
            <div className="col-md-12 text-left px-5 mt-4">
              <Link to={node.CTALink} className="text-dark">
                <h5 className="font-weight-bolder">{node.Title}</h5>
              </Link>
              <p className="product-par mb-3 pr-5">
                {node.Description}
              </p>
              {node.CtAText &&
                <div to={node.CTALink} className="parallelogram mb-4">
                  <Link to={node.CTALink} className="text-dark">
                    {node.CtAText}
                  </Link>
                </div>
              }
            </div>

          </>
        ))}
      </Slider>
    </div>

  )
}


