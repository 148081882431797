import React from "react"
import Slider from "react-slick"
import { Link, graphql, useStaticQuery } from "gatsby"
function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <span>prev</span>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <span>next</span>
    </div>
  )
}

export default function CarouselProduct(props) {
  const data = useStaticQuery(graphql`
    query {
      allProductsJson(sort: {fields: OrderId,order:ASC}) {
        nodes {
          OrderId
          CTALink
          Image
          Title
        }
      }
    }
  `)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.94,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Slider {...settings}>
      {data.allProductsJson.nodes.map((node, index) => {
        return (
          <>
            <div className="mt-4 section-link">
              <div className="pickgradient pickgradient card h-100">
                <Link to={"/products/" + node.CTALink}>
                  <img alt={node.Title} src={node.Image} height="auto" />
                  <p className="location" style={{ fontSize: ' 14.5px' }}>
                    {node.Title}
                    <br />
                  </p>
                  <p className="parallelogram mb-4 mr-3 active-filter">
                    <Link
                      className="text-dark "
                      to={"/products/" + node.CTALink}
                    >
                      MORE
                    </Link>
                  </p>
                </Link>
              </div>
            </div>
          </>
        )
      })}
    </Slider>
  )
}
