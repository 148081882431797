import React, { useState } from "react"
import Layout from "../retech/components/layout/Layout"
import { Button, InputGroup } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import Media from "../retech/components/MediaCenter/Index"
import Service from "../retech/components/Service/Index"
import { SocialIcon } from "react-social-icons"
import { Helmet } from "react-helmet"
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
} from "react-share"
import { Link, graphql } from "gatsby"
import CarouselProduct from "../retech/components/Product/Index"
import ModalVideo from "react-modal-video"
import "react-modal-video/css/modal-video.css"

export default function Home({ data }) {
  const homehero = data.homehero.nodes
  const services = data.services.nodes
  const widgetcontent2 = data.widgetcontent2.nodes
  const servicewidget2 = data.servicewidget2.nodes
  const metadata = data.metadata.nodes
  const socialmedia = data.socialmedia.nodes


  const url = typeof window !== "undefined" ? window.location.href : ""
  const [isOpen, setOpen] = useState(false)
  return (
    <Layout>
      <Helmet>
        <title>Retech | Home</title>

        <meta
          name="description"
          content={data.metadata.nodes[0].HomeMetaDescription}
        />
        <meta name="keywords" content={data.metadata.nodes[0].Homekeywords} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={data.metadata.nodes[0].HomeMetaTitle}
        />
        <meta
          property="og:description"
          content={data.metadata.nodes[0].HomeMetaDescription}
        />
        <meta property="og:image" content={url + "logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:title"
          content={data.metadata.nodes[0].HomeMetaTitle}
        />
        <meta
          property="twitter:description"
          content={data.metadata.nodes[0].HomeMetaDescription}
        />
        <meta property="twitter:image" content={url + "logo.svg"} />
      </Helmet>

      <div className="container-fluid px-0">
        {homehero.map(node => {
          return node.Hero_Home
            ? node.Hero_Home.map(q => (
              <>
                <div
                  className="show_bg_desktop d-none d-sm-block"
                  style={{
                    backgroundImage:
                      "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" +
                      q.Image +
                      ")",
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 pt-5 mt-4 pr-5">
                        <h1 className="font-wight-boder pb-5">{q.Title}</h1>
                        <p className="text-white pt-4 pb-4">
                          {q.description}
                        </p>
                        <InputGroup className="mb-3">
                          <InputGroup.Append>
                            <Button
                              variant="outline-secondary"
                              className="text-white"
                              onClick={() => setOpen(true)}
                            >
                              {q.CTAText}{" "}
                              <FontAwesomeIcon
                                className="ml-3 font-size-sm"
                                icon={faPlay}
                              />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <ModalVideo
                          channel="youtube"
                          autoplay={{ autoplay: 1 }}
                          isOpen={isOpen}
                          videoId="Ih39tr4sGgo"
                          onClose={() => setOpen(false)}
                        />
                      </div>
                      <div className="col-md-6 pt-5 social">
                        {socialmedia.map((node, index) => {
                          return (
                            <>
                              <div className="Demo__some-network mb-1">
                                <LinkedinShareButton
                                  url={node.LinkedIn}
                                  quote="{title}"
                                  className="Demo__some-network__share-button"
                                >
                                  <LinkedinIcon size={72} bgstyle="#000" />
                                </LinkedinShareButton>
                              </div>
                              <div className="Demo__some-network mb-1">
                                <FacebookShareButton
                                  url={node.Facebook}
                                  quote="{title}"
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={72} bgstyle="#000" />
                                </FacebookShareButton>
                              </div>

                              <div className="Demo__some-network mb-1">
                                <PinterestShareButton
                                  url={node.Youtube}
                                  quote="{title}"
                                  className="Demo__some-network__share-button"
                                >
                                  <SocialIcon
                                    url={node.Youtube}
                                    network="youtube"
                                    size={72}
                                    bgstyle="#000"
                                  />
                                </PinterestShareButton>
                              </div>
                            </>
                          )
                        })}

                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
            : null
        })}

        {homehero.map(node => {
          return node.Hero_Home
            ? node.Hero_Home.map(q => (
              <>
                <div
                  className="show_bg_mobile d-block d-sm-none"
                  style={{
                    backgroundImage:
                      "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" +
                      q.Image +
                      ")",
                  }}
                >
                  <div className="col-md-4 pt-5">
                    <h1 className="font-wight-boder pb-5">{q.Title}</h1>
                    <p className="text-white pt-4 pb-4">{q.description}</p>
                    <InputGroup className="mb-3">
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          className="text-white"
                          onClick={() => setOpen(true)}
                        >
                          {q.CTAText}{" "}
                          <FontAwesomeIcon
                            className="ml-3 font-size-sm"
                            icon={faPlay}
                          />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <ModalVideo
                      channel="youtube"
                      youtube={{ autoplay: 1 }}
                      isOpen={isOpen}
                      videoId="Ih39tr4sGgo"
                      onClose={() => setOpen(false)}
                    />
                  </div>
                </div>
              </>
            ))
            : null
        })}
      </div>

      <div className="container-fluid" id="grad1">
        <div className="container">
          <div className="col-md-12 py-5">
            <div className="row">
              <div className="col-xxl-4 col-lg-4 col-md-5  align-self-center">
                {services.map((node, index) => {
                  return (
                    <>
                      <h3 className="mb-4 font-weight-bolder">
                        {node.ServiceWidgetTitle}
                      </h3>
                      <p className="text-muted">
                        {node.ServiceWidgetintro}
                      </p>
                      <Link
                        to={node.ServiceWidgetCTALink}
                        className="font-weight-bolder see-all"
                      >
                        <p>{node.ServiceWidgetCTAText} </p>
                        <FontAwesomeIcon
                          className="ml-3"
                          icon={faChevronCircleRight}
                        />{" "}
                      </Link>
                    </>
                  )
                })}
              </div>
              <div className="col-xxl-8 col-lg-8 col-md-7 Product">
                <CarouselProduct />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="col-md-12">
            <div className="row py-5">
              {servicewidget2.map((node, index) => {
                return (
                  <>
                    <div
                      className="col-xl-5 col-lg-5 col-md-5 d-none d-sm-block"
                      key={index}
                    >
                      <img
                        src={node.Widget1Image}
                        width="460px"
                        height="600px"
                        alt="background"
                      />
                    </div>
                    <div
                      className="col-xxl-5 col-lg-6 col-md-6  ml-5 pt-4 ml-xs-0"
                      key={index}
                    >
                      <h3 className="mb-4 font-weight-bolder">
                        {node.Widget1Title}
                      </h3>
                      <p className="product-par">{node.Widget1Intro}</p>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          {servicewidget2.map((node, index) => {
            return (
              <>
                <div className="col-xxl-7 col-lg-8 col-md-10 service-section d-block d-sm-none Service-mobile" style={{
                  backgroundImage:
                    "url(" +
                    node.Widget1ImageMobile +
                    ")",
                }}>
                  <Service />
                </div>
              </>
            )
          })}
        </div>
        <div className="col-xxl-7 col-lg-8 col-md-10 service-section  d-none d-sm-block">
          <Service />
        </div>
      </div>
      <div className="container">
        <div className="col-md-12 border-bottom">
          <div className="row py-5">
            <div className="col-xxl-5 col-lg-7 col-md-7 Common mx-auto text-center">
              {widgetcontent2.map((node, index) => {
                return (
                  <>
                    <h4 className="mb-4 font-weight-bolder">
                      {node.Widget2Title}
                    </h4>
                    <p className="product-par">{node.Widget2Intro}</p>
                    <Link
                      to="/faq"
                      className="font-weight-bolder see-all justify-content-center"
                    >
                      <p>{node.Widget2CTAText} </p>
                      <FontAwesomeIcon
                        className="ml-3"
                        icon={faChevronCircleRight}
                      />{" "}
                    </Link>
                  </>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-md-12 py-5 Media ">
          <Media />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    homehero: allHomeJson {
      nodes {
        Hero_Home {
          CTALink
          CTAText
          Image
          Title
          description
        }
      }
    }
    socialmedia: allHomeJson(filter: {Facebook: {ne: null}}) {
      nodes {
        Youtube
        Facebook
        LinkedIn
      }
    }
    services: allHomeJson(filter: { ServiceWidgetCTAText: { ne: null } }) {
      nodes {
        ServiceWidgetCTALink
        ServiceWidgetCTAText
        ServiceWidgetTitle
        ServiceWidgetintro
      }
    }

    servicewidget2: allHomeJson(filter: { Widget1Title: { ne: null } }) {
      nodes {
        Widget1Image
        Widget1ImageMobile
        Widget1Intro
        Widget1Title
      }
    }
    widgetcontent2: allHomeJson(filter: { Widget2Title: { ne: null } }) {
      nodes {
        Widget2CTALink
        Widget2CTAText
        Widget2Intro
        Widget2Title
      }
    }
    metadata: allMetadataJson(filter: { HomeMetaTitle: { ne: null } }) {
      nodes {
        HomeMetaDescription
        HomeMetaTitle
        Homekeywords
      }
    }
  }
`
